import cs from "classnames"

import moduleCss from "./index.module.scss";
import logo_top from "@/assets/img/logo_top.png";
import logo_box from "@/assets/img/emailbox.png";
import { useCallback } from "react";

export default function Header() {
    const menus = [
        { name: 'Home', href: '/', },
        {name:'Product', href:'/product'},
        {name:'Solutions', href: '/solutions'},
        {name:'About Us', href:'/about',},
    ]
    const menusRight = ['Contact Us', 'Login',]

    return (
        <header>
            <div className={moduleCss.left}>
                <a href="/">
                  <img src={logo_top} alt="logo" style={{width: '180px'}}></img>
                </a>
                  <div className={moduleCss.route}>
                      {menus.map((item, index) => {
                          return <a className={cs(moduleCss.item, {'_active': window.location.pathname == item.href})} key={index} href={item.href}>{ item.name}</a>
                       })}
                </div>
              </div>
              <div className={moduleCss.right}>
                  <div className={moduleCss.contact}>
                      <img src={logo_box} alt="emailbox" style={{marginRight: '5px'}}/>
                      <span>{ menusRight[0]}</span>
                  </div>
                  <div className={moduleCss.login}>{ menusRight[1]}</div>
              </div>
          </header>
    )
}