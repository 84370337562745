import {
  createBrowserRouter,
  Navigate,
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import "./App.css"
import Dashboard from "./dashboard"
import Product from "./product"
import About from "./about"

export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Routes>
                <Route path="/" element={<Dashboard/>}></Route>
                <Route path="/product" element={<Product/>}></Route>
                <Route path="/about" element={<About/>}></Route>
                <Route path="*" element={<Navigate to='/'/>}></Route>
          </Routes>
      </BrowserRouter>
    </div>
  );
}
