import moduleCss from "./index.module.scss";
import logo_down from "@/assets/img/logo_down.png";
import chat_png from "@/assets/img/wechat.png";
import loc_png from "@/assets/img/loc.png";

export default function Header() {
    const footerPart = {
        menus: ['Home','Product','Solutions','About Us',],
        loc: '3rd Floor,Yuanzheng Science Park,Bantian Street,Longgang District,Shenzhen,China',
        pri: ['隐私政策','粤ICP备2024185077号-1',]
    }
    return (
        <footer>
              <div className={moduleCss.f1}>
                  <div className={moduleCss.left}>
                      <img src={logo_down}  alt="logo"></img>
                      <ul>
                          {footerPart.menus.map((item, index) => { 
                              return <li key={index}>{ item}</li>
                          })}
                      </ul>
                  </div>
                  <div className={moduleCss.right}>
                      <div style={{ display: 'flex', alignItems: 'center', }}><img src={loc_png} /><span>{ footerPart.loc}</span></div>
                      <img src={chat_png} alt="二维码"/>
                  </div>
              </div>
              <div className={moduleCss.f2}>
                  <span>{ footerPart.pri[0]}</span>
                  <span className={moduleCss.sep}>|</span>
                  <span><a href="https://beian.miit.gov.cn/" target="_blabk">{ footerPart.pri[1]}</a></span>
              </div>
          </footer>
    )
}