import moduleCss from "./index.module.scss";
import cs from "classnames"
import { useEffect, useState } from "react";
import logo_top from "@/assets/img/logo_top.png";
import logo_box from "@/assets/img/head_menu.png";


export default function Header() {
    const menus = [
        { name: 'Home', href: '/', },
        {name:'Product', href:'/product'},
        {name:'Solutions', href: '/solutions'},
        {name:'About Us', href:'/about',},
    ]
    const menusRight = ['Contact Us', 'Login',]
    const [showDrop, setShowDrop] = useState(false)

    const clickMenu = () => { 
        setShowDrop(!showDrop)
    }
    

    return (
        <div className={moduleCss.header}>
            <header>
                <a href="/">
                    <img src={logo_top} className={moduleCss.logo}></img>
                </a>
                    <img src={logo_box} className={moduleCss.menu} onClick={clickMenu}></img>
            </header>
            {showDrop && <div className={moduleCss.drop}>
                {menus.map((item, index) => {
                    return (<a className={cs(moduleCss.item, { [moduleCss._active]: item.href == window.location.pathname })}
                        href={item.href}
                        onClick={() => setShowDrop(false)}
                    >{item.name}</a>)
                })
                }
            </div>}
        </div>
    )
}