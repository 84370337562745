import moduleCss from "./home.module.scss";
import cs from "classnames"
import { useState, useEffect, useRef, useCallback, } from "react";

import { Swiper, SwiperSlide, } from 'swiper/react';
import { Autoplay, } from 'swiper/modules';
import { Input } from 'antd';

import Head from "./../header"
import Foot from "./../footer"

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import "./swiperhome.css"

import s1 from "@/assets/img/service/s1.png"
import s2 from "@/assets/img/service/s2.png"
import s3 from "@/assets/img/service/s3.png"
import s4 from "@/assets/img/service/s4.png"
import s5 from "@/assets/img/service/s5.png"

import icon1 from "@/assets/img/service/ts1.png"
import icon2 from "@/assets/img/service/ts2.png"
import icon3 from "@/assets/img/service/ts3.png"
import icon4 from "@/assets/img/service/ts4.png"
import icon5 from "@/assets/img/service/ts5.png"

import f_icon1 from "@/assets/img/friends/s1.png"
import f_icon2 from "@/assets/img/friends/s2.png"
import f_icon3 from "@/assets/img/friends/s3.png"
import f_icon4 from "@/assets/img/friends/s4.png"
import f_icon5 from "@/assets/img/friends/s5.png"
import f_icon6 from "@/assets/img/friends/s6.png"
import f_icon7 from "@/assets/img/friends/s7.png"
import f_icon8 from "@/assets/img/friends/s8.png"
import f_icon9 from "@/assets/img/friends/s9.png"
import f_icon10 from "@/assets/img/friends/s10.png"


import search_icon_png from "@/assets/img/search.png"
import arrow_png from "@/assets/img/arrow.png"

export default function App() {
    const bannerPart = {
        h1: 'Global Supply Chain Solutions',
        h2: 'As a global digital logistics expert, we offers consultative services covering sea freight,trucking, customs clearance, insurance, overseas warehousing, and destination services',
        btn_text: 'Consult now',
        img: '/img/banner.png',
    }

    const servicePart = {
        h1: 'Our Services',
        h2: 'services covering sea freight, trucking, customs declaration&clearance, insurance and overseas warehousing',
        blocks:
        [
            {
                t1: 'Global Supply Chain Solutions',
                t2: 'We provide customized supply chain solutions to meet the various needs of customers',
                img: s1,
            },
            {
                t1: 'Sea Freight',
                t2: 'We provide FCL between China & Turkey，all ports are covered',
                img: s2,
            },
            {
                t1: 'Bilateral Trucking',
                t2: 'We provide trucking services including factory loading & discharge',
                img: s3,
            },
            {
                t1: 'Bilateral Customs declaration and clearance',
                t2: 'We have wealth of powerful customs broker resources',
                img: s4,
            },
            {
                t1: 'Bilateral Storage',
                t2: 'We provide oversea warehousing through digitalized management',
                img: s5,
            },
        ],
    }
    const aiPart = {
        h1: 'Our  Advantages',
        h2: 'services covering sea freight, trucking, customs declaration&clearance, insurance and overseas warehousing',
        menus: [
            {
                img: icon1,
                title: 'Favourable price',
            },
            {
                img: icon2,
                title: 'Risk Management',
            },
            {
                img: icon3,
                title: 'One-Stop Solutions',
            },
            {
                img: icon4,
                title: 'Strong Business Team',
            },
            {
                img: icon5,
                title: 'Strong IT Team',
            },
            
        ],
        btn1: 'Consult now',
        btn2: 'Learn more',
        items: [
            {
                h1: 'Favourable price',
                h2: 'We are providing 2%-5% lower compare to market price',
                ul: [
                    'Lowest sea freight price',
                    'Fixed Distination Fee',
                    'We have serval branches in China and Turkey, to provide most cost-effective services',
                ],
            },
            {
                h1: 'Risk Management',
                h2: 'We are providing free supply chain diagnosis service to help customer reduce risk.',
                ul: [
                    'We provide extra insurance to protect our customer rights and interests',
                    'All cargo data is real-time monitored and traceable online,',
                    'All risk will be alerted by platform in time, provide security alerts for your cargos.',
                ],
            },
            {
                h1: 'One-Stop Solutions',
                h2: 'We provide customized precise and efficient supply chain solutions to meet the various needs of customers',
                ul: [
                    'Trucking and customs declaration at loading place',
                    'Container Booking',
                    'Customs clearance and trucking at destination place',
                    'Warehouse management',
                ],
            },
            {
                h1: 'Strong Business Team',
                h2: 'Our Business team with 26 years of Sea Freight experience.',
                ul: [
                    'Long-term and stable partnerships with top shipping companies',
                    'Stable cooperation with container trucking companies, covering major ports in China.',
                    'Several branches in China & Turkey',
                ],
            },
            {
                h1: 'Strong IT Team',
                h2: '17 Years experience in Digtial Supply Chain IT Platform  &  8 Years experience in Artificial Intelligence Algorithm Research.',
                ul: [
                    'Our Platform are offering free tools as Cargo Tracking, Container Usage Checking, Vessel Schedule, Port status Checking.',
                    'Our Platform had established EDI & API Connection with top shipping companies',
                    'AI algorithm help to forecast of shipping schedule',
                ],
            },
        ],
    }
    const parterPart = {
        h1: 'Our Partner',
        h2: 'services covering sea freight, trucking, customs declaration&clearance, insurance and overseas warehousing',
        blocks: [
            f_icon1,
            f_icon2,
            f_icon3,
            f_icon4,
            f_icon5,
            f_icon6,
            f_icon7,
            f_icon8,
            f_icon9,
            f_icon10,],
    }
    

    const searchPart = {
        left: ['Location', 'Freight', 'Rates', 'Quote',],
        placeholder: 'Please input your quote',
    }

    const tradePart = {
        h1: 'Terms of Trade',
        h2: 'services covering sea freight, trucking, customs declaration&clearance, insurance and overseas warehousing',
        menus: ['FOB','CIF','EXW','DDU','DDP', 'DAP'],
    }

    
    const intervalRef = useRef(null);
    const [targetService, setTargetService] = useState(0);
    const targetServiceref = useRef(null);
    const [targetSp, setTargetSp] = useState(0);
    const pauseRef = useRef(null);
    const swiperInstanceRef = useRef(null);
    
    const changeSp = useCallback(() => {
        swiperInstanceRef.current.slideNext();
    }, [])
    
    const changeSlide = useCallback((index) => {
        swiperInstanceRef.current.slideTo(index);
    }, [])
    
    const onSlideChange = useCallback((event) => { 
        setTargetSp(event.activeIndex);
    }, [])

    const changeSr = useCallback((index) => {
        if (index !== targetServiceref.current) {
            targetServiceref.current = index
            setTargetService(index);
        }
    }, [])
    
    

    useEffect(() => {
        targetServiceref.current = 0
        if (!intervalRef.current) {
            intervalRef.current = setInterval(() => {
                if (pauseRef.current === true) {
                    return;
                }
                targetServiceref.current = (targetServiceref.current + 1) % 5
                setTargetService(targetService => {
                    return (targetService + 1) % 5;
                })
            }, 2000)
        }
     }, [])
  return (
      <div className={moduleCss.wrap}>
          <Head />
          <div className={moduleCss.banner}>
              <div className={moduleCss.title1}>{ bannerPart.h1}</div>
              <div className={moduleCss.title2}>{ bannerPart.h2}</div>
              <div className={moduleCss.orangebtn}>
                  <span>{ bannerPart.btn_text}</span>
                  <img src={arrow_png} alt=">" />
              </div>
          </div>
          <div className={moduleCss.search}>
              <div style={{display: 'flex'}}>
                  {searchPart.left.map((item, index) => {
                      return (<div key={index} className={cs(moduleCss.item, {[moduleCss._active]: index == 0})}>
                          <span>{item}</span>
                          <span className={moduleCss.sep}></span>
                      </div>)
                  })}
              </div>
              <div className={moduleCss.sep1}></div>
              <div className={moduleCss.bottom }  style={{display: 'flex', alignItems: 'center'}}>
                  <Input style={{ width: '100%' }} placeholder={searchPart.placeholder} />
                  <img src={search_icon_png} style={{width:'40px',height:'40px'}}></img>
              </div>
          </div>
          <div className={moduleCss.service}>
              <div>
                <h1>{servicePart.h1}</h1>
                  <h4>{servicePart.h2 }</h4>
              </div>
              <div className={moduleCss.serviceArr} onMouseEnter={() => { pauseRef.current = true }} onMouseLeave={() => {pauseRef.current = false }}>
                  {servicePart.blocks.map((item, index) => { 
                      return (<div className={cs({ [moduleCss.targetService]: targetService == index, [moduleCss.item]: true, })}
                          onMouseEnter={() => changeSr(index)}
                          style={{ backgroundImage: `url(${item.img})` }}
                          key={index}
                      >
                      {
                              targetService === index ? <><h2>{ item.t1}</h2>
                                  <h3>{ item.t2}</h3></> : <div>{ item.t1}</div>
                    }
                      
                  </div>)
                  })}
              </div>
          </div>
          <div className={moduleCss.ai}>
              <div className={moduleCss.aitop}>
                <div>
                      <h1>{ aiPart.h1}</h1>
                      <h4>{ aiPart.h2}</h4>
                </div>
                <div className={moduleCss.aiMethods}>
                    {aiPart.menus.map((item, index) => {
                        return (<div className={cs(moduleCss.aititle, { [moduleCss.active_aititle]: index == targetSp })} key={index} onClick={() => changeSlide(index)}>
                            <div className={moduleCss.img}>
                                <img src={ item.img}></img>
                            </div>
                            <div>{item.title}</div>
                        </div>)
                    })}
                </div>
              </div>
              
              <Swiper
                spaceBetween={30}
                centeredSlides={true}                
                  modules={[Autoplay]}
                  onSwiper={(swiper) => { swiperInstanceRef.current = swiper; }}
                  onSlideChange={(event) => onSlideChange(event)}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                      }}
                  className="mySwiper"
              >
                  {aiPart.items.map((item, index) => { 
                      return (
                          <SwiperSlide key={index}>
                        <div className={moduleCss.slideItem}>
                                  {/* <div className={moduleCss.left}>
                                      <img src={ item.img}></img>
                                    </div> */}
                            <div className={moduleCss.right}>
                                <div className={moduleCss.leftC}>
                                          <div>{ item.h1}</div>
                                          <div>{ item.h2}</div>
                                          <ul>
                                              {item.ul.map((li, index) => { 
                                                  return <li key={index}><span></span><span>{li}</span></li>
                                              })}
                                    </ul>
                                </div>
                                <div className={moduleCss.rightC}>
                                          <div>{ aiPart.btn1}</div>
                                          <div>{ aiPart.btn2}</div>
                                </div>
                            </div>
                        </div>
                  </SwiperSlide>
                      )
                  })}
                  
              </Swiper>
              {/* <button onClick={changeSp}>切换</button> */}
          </div>
          <div className={moduleCss.friends}>
              <div>
                  <h1>{ parterPart.h1}</h1>
                  <h4>{ parterPart.h2}</h4>
              </div>
              <div className={moduleCss.friendslogoimgs}>
                <div className={moduleCss.logoarr}>
                    {parterPart.blocks.slice(0, 5).map((item, index) => {
                        return (<div className={moduleCss.friendsLogo} key={ index}>
                            <img src={item}></img>
                        </div>)
                    })}
                </div>
                <div className={moduleCss.logoarr}>
                    {parterPart.blocks.slice(5).map((item, index) => {
                        return (<div className={moduleCss.friendsLogo} key={index}>
                            <img src={item}></img>
                        </div>)
                    })}
                </div>
              </div>
          </div>

          <div className={moduleCss.tradedata}>
                <div>
                  <h1>{ tradePart.h1}</h1>
                  <h4>{ tradePart.h2}</h4>
              </div>
              <div className={moduleCss.item}>
                  {tradePart.menus.map((item, index) => {
                      return (<span key={index} className={cs({ [moduleCss.fob_active]: index == 0 })}>
                          <span>{item}</span>
                          <span></span>
                      </span>)
                   })}
              </div>
          </div>
          <Foot />
    </div>
  );
}
